<template>
  <div>
    <header class="header br-bottom">
      <div class="header-logo h2">Find clients</div>
      <div class="header-rg">
        <div class="text-md color-grey">Found 234 clients</div>
      </div>
    </header>
    <div class="row row-100">
      <div class="col-lg-3">
        <div v-if="!filterLoader && filter">
          <div class="lb-mb-15 find-ch-filter">
            <accordin-slide :open="true" class="type-three">
              <template v-slot:header>
                <div class="h4">Language</div>
              </template>
              <template v-slot:content>
                <div class="accordion-inner">
                  <div class="lb-mb-8">
                    <div
                      class="find-ch-item"
                      v-for="(item, i) in filter.language.slice(0,4)"
                      :key="i"
                    >
                      <label class="checkbox-entry redonly">
                        <input
                          type="checkbox"
                          class="subscribe-input"
                          :value="item.name"
                          v-model="languageInput"
                        >
                        <span>{{item.name}}</span>
                      </label>
                      <span class="badge-ml bg-grey color-dark-2 text-sm">{{item.quantity}}</span>
                    </div>
                  </div>
                  <div
                    class="show-more"
                    v-if="filter.language.length > 5"
                  >
                    <button
                      class="btn btn-ghost"
                      @click="showMore"
                    >
                      Show all
                    </button>
                    <div class="show-more-inner">
                      <div
                        class="find-ch-item"
                        v-for="(item, i) in filter.language.slice(5,filter.language.length)"
                        :key="i"
                      >
                        <label class="checkbox-entry redonly">
                          <input
                            type="checkbox"
                            class="subscribe-input"
                            :value="item.name"
                            v-model="languageInput"
                          >
                          <span>{{item.name}}</span>
                        </label>
                        <span class="badge-ml bg-grey color-dark-2 text-sm">{{item.quantity}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </accordin-slide>
          </div>
          <div class="lb-mb-15 find-ch-filter">
            <accordin-slide :open="true" class="type-three">
              <template v-slot:header>
                <div class="h4">Areas</div>
              </template>
              <template v-slot:content>
                <div class="accordion-inner">
                  <div class="lb-mb-8">
                    <div
                      class="find-ch-item"
                      v-for="(item, i) in filter.areas.slice(0,4)"
                      :key="i"
                    >
                      <label class="checkbox-entry redonly">
                        <input
                          type="checkbox"
                          class="subscribe-input"
                          :value="item.name"
                          v-model="areasInput"
                        >
                        <span>{{item.name}}</span>
                      </label>
                      <span class="badge-ml bg-grey color-dark-2 text-sm">{{item.quantity}}</span>
                    </div>
                  </div>
                  <div
                    class="show-more"
                    v-if="filter.areas.length > 5"
                  >
                    <button
                      class="btn btn-ghost"
                      @click="showMore"
                    >
                      Show all
                    </button>
                    <div class="show-more-inner">
                      <div
                        class="find-ch-item"
                        v-for="(item, i) in filter.areas.slice(5,filter.areas.length)"
                        :key="i"
                      >
                        <label class="checkbox-entry redonly">
                          <input
                            type="checkbox"
                            class="subscribe-input"
                            :value="item.name"
                            v-model="areasInput"
                          >
                          <span>{{item.name}}</span>
                        </label>
                        <span class="badge-ml bg-grey color-dark-2 text-sm">{{item.quantity}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </accordin-slide>
          </div>
          <div class="lb-mb-15 find-ch-filter">
            <accordin-slide :open="true" class="type-three">
              <template v-slot:header>
                <div class="h4">Career goal</div>
              </template>
              <template v-slot:content>
                <div class="accordion-inner">
                  <div class="lb-mb-8">
                    <div
                      class="find-ch-item"
                      v-for="(item, i) in filter.goal.slice(0,4)"
                      :key="i"
                    >
                      <label class="checkbox-entry redonly">
                        <input
                          type="checkbox"
                          class="subscribe-input"
                          :value="item.name"
                          v-model="goalInput"
                        >
                        <span>{{item.name}}</span>
                      </label>
                      <span class="badge-ml bg-grey color-dark-2 text-sm">{{item.quantity}}</span>
                    </div>
                  </div>
                  <div
                    class="show-more"
                    v-if="filter.goal.length > 5"
                  >
                    <button
                      class="btn btn-ghost"
                      @click="showMore"
                    >
                      Show all
                    </button>
                    <div class="show-more-inner">
                      <div
                        class="goals-item"
                        v-for="(item, i) in filter.goal.slice(5,filter.goal.length)"
                        :key="i"
                      >
                        <div class="row align-items-center justify-content-between">
                          <div class="col-auto">
                            <label class="checkbox-entry redonly">
                              <input
                                type="checkbox"
                                class="subscribe-input"
                                :value="item.name"
                                v-model="goalInput"
                              >
                              <span>{{item.name}}</span>
                            </label>
                          </div>
                          <div class="col-auto">
                            <div class="row row-12 nowrap align-items-center justify-content-end text-right">
                              <div class="col-auto">
                                <span class="badge-ml bg-grey color-dark-2 text-sm">{{item.quantity}}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </accordin-slide>
          </div>
        </div>
        <div v-else class="loader-wrapper">
          <div class="loader-circle">
            <img src="@/assets/img/loader-icon.png" alt="loader">
          </div>
        </div>
      </div>
      <div class="col-lg-9">
        <div v-if="!clientsLoader">
          <div v-if="resultVacancies.length > 0" class="row thumbnail-coach-wrapper">
            <div
              class="col-lg-6"
              v-for="(item, i) in resultVacancies"
              :key="i"
            >
              <div class="thumbnail-coach">
                <div class="info-users">
                  <div class="image">
                    <img :src="item.imageUrl" alt="image">
                  </div>
                  <div class="info">
                    <div class="name h4 lb-mb-4">{{item.name}}</div>
                    <div class="prof">{{item.prof}}</div>
                  </div>
                  <button @click="openDetailInfo(i)" class="btn btn-outlined btn-icon">
                    <img src="@/assets/img/icons/icon-remove_red_eye.svg" alt="icon">
                  </button>
                </div>
                <div class="lb-mb-15">
                  <div class="text-md lb-mb-4">Specifications</div>
                  <div class="badge-ml-wrapper">
                  <span
                    class="badge-ml bg-grey color-dark-2"
                    v-for="(spec, a) in item.specifications"
                    :key="a + 'c'"
                  >
                    {{spec}}
                  </span>
                  </div>
                </div>
                <div class="text-md lb-mb-15">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="lb-mb-4">Languages</div>
                      <div class="color-dark" v-if="item.languages">
                        <span
                          v-for="(ln, i) in item.languages"
                          :key="i + 'sa'"
                        >{{ln}}<span v-if="item.languages.length > i + 1">, </span></span>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="lb-mb-4">Career goal</div>
                      <div class="color-dark">{{item.goal}}</div>
                    </div>
                  </div>
                </div>
                <div class="text-md">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="lb-mb-4">Location</div>
                      <div class="color-dark">{{item.location}}</div>
                    </div>
                    <div class="col-lg-6">
                      <div class="lb-mb-4">Area</div>
                      <div class="color-dark">{{item.area}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="loader-wrapper">
          <div class="loader-circle">
            <img src="@/assets/img/loader-icon.png" alt="loader">
          </div>
        </div>
      </div>
    </div>
    <pop-up
      :show-modal="showModal"
      slide-animation="slideleft"
      view-modal-container="modal-container-two type-three"
      @closemodal="showModal = false"
    >
      <div class="h2 color-dark lb-mb-40">Client</div>
      <div class="row row-24 nowrap">
        <div class="col-w-100">
          <div class="lb-mb-15">
            <div class="color-dark lb-mb-4 h2">Helen Frost</div>
            <div class="color-grey">Front-End Developer</div>
          </div>
          <div class="lb-mb-15">
            <div class="badge-ml-wrapper">
              <span class="badge-ml bg-grey color-dark-2">HTML5</span>
              <span class="badge-ml bg-grey color-dark-2">CSS3</span>
            </div>
          </div>
          <div class="lb-mb-15 color-dark text-md">
            <p>Hey! My name is Helen. I'm 28 years old. For the last 10 years I have been working as an account manager. I thought about changing my profession to developers. I really like it and already something is working out. I need help in building my development plan, since without it it is difficult to understand where to start studying, to find out the answers to my questions.</p>
          </div>
          <table class="table min-first-col text-md lb-mb-40">
            <tbody>
            <tr>
              <td>Career goal</td>
              <td><span class="text-media color-dark">Change career path</span></td>
            </tr>
            <tr>
              <td>Location</td>
              <td><span class="text-media color-dark">USA, Los Angeles</span></td>
            </tr>
            <tr>
              <td>Languages</td>
              <td><span class="text-media color-dark">English</span></td>
            </tr>
            <tr>
              <td>Twitter</td>
              <td><a href="https://linkedin.com/in/kathrynmurphy/" class="btn btn-link">twitter.com/helenfrost</a></td>
            </tr>
            <tr>
              <td>Facebook</td>
              <td><a href="https://facebook.com/kathrynmurphy" class="btn btn-link">facebook.com/helenfrost</a></td>
            </tr>
            </tbody>
          </table>
          <button class="btn btn-primary">Send a request</button>
        </div>
        <div class="col">
          <div class="lab-dash-image-user size-three">
            <img src="@/assets/img/image-user-02.jpg" alt="image">
          </div>
        </div>
      </div>
    </pop-up>
  </div>
</template>

<script>
import AccordinSlide from '@/components/AccordionSlide'
import PopUp from '@/components/PopUp'
export default {
  data () {
    return {
      clientsLoader: false,
      filterLoader: false,
      showModal: false,
      activeItem: 'tab-1',
      popUpContent: '',
      languageInput: [],
      areasInput: [],
      goalInput: []
    }
  },
  computed: {
    clients: function () {
      return this.$store.getters.clients
    },
    filter: function () {
      return this.$store.getters.clientsFilters
    },
    languageFilter: function () {
      if (this.languageInput.length > 0) {
        return this.clients.filter((item) => {
          const a = [...item.languages]
          return this.diff(this.languageInput, a)
        })
      } else {
        return this.clients
      }
    },
    areasFilter: function () {
      if (this.areasInput.length > 0) {
        return this.languageFilter.filter((item) => {
          const a = [item.area]
          return this.diff(this.areasInput, a)
        })
      } else {
        return this.languageFilter
      }
    },
    goalFilter: function () {
      if (this.goalInput.length > 0) {
        return this.areasFilter.filter((item) => {
          const a = [item.goal]
          return this.diff(this.goalInput, a)
        })
      } else {
        return this.areasFilter
      }
    },
    resultVacancies: function () {
      // return this.vacancies.filter(v => {
      //   if (this.searchSkills === '') {
      //     return true
      //   } else {
      //     return v.vacancy.toLowerCase().includes(this.searchVacancy.toLowerCase())
      //   }
      // })
      return this.goalFilter
    }
  },
  methods: {
    subIsActive: function (v) {
      const paths = Array.isArray(v) ? v : [v]

      return paths.some(path => {
        return this.$route.path.indexOf(path) === 0
      })
    },
    showMore: function (e) {
      e.target.closest('.show-more').classList.add('show')
    },
    openDetailInfo: function (i) {
      this.popUpContent = this.resultVacancies[i]
      this.showModal = true
    },
    closeDetailInfo: function () {
      this.showModal = false
      this.popUpContent = ''
    },
    isActive (e) {
      return this.activeItem === e
    },
    setActive (menuItem) {
      this.activeItem = menuItem
    },
    diff: function (a, b) {
      return a.filter(n => b.indexOf(n) > -1).length
    }
  },
  mounted () {
    this.clientsLoader = true
    this.$store.dispatch('fetchClients')
      .finally(() => {
        this.clientsLoader = false
      })
    this.filterLoader = true
    this.$store.dispatch('fetchClientsFilters')
      .finally(() => {
        this.filterLoader = false
      })
  },
  created () {
    if (this.$route.query.usr) {
      this.$router.push(this.$route.path)
    }
  },
  components: {
    AccordinSlide,
    PopUp
  }
}
</script>

<style lang="scss">
.find-ch{
  &-filter{
    max-width: 280px;
    .badge-ml{
      border-radius: 16px;
    }
    .checkbox-entry span{
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  &-item{
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
    align-items: center;
  }
}
.accordion-item{
  &.type-three{
    .accordion-header{
      display: flex;
      align-items: center;
    }
    .accordion-inner{
      padding-left: 0!important;
      padding-top: 10px!important;
    }
    .btn-accordion{
      margin-left: auto!important;
      flex: 0 0 20px;
      width: 20px;
      height: 20px;
    }
  }
}
.show-more{
  &-inner{
    display: none;
  }
  &.show{
    .btn{
      display: none;
    }
    .show-more-inner{
      display: block;
    }
  }
}
</style>
